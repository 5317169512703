<template>
  <div class="section" style="min-height: 70vh;">
    <UserFeedback :target="t('componentNames.userCreator')" class="end mr-4 mt-1"></UserFeedback>
    <ValidationObserver ref="observer" v-slot="{ invalid }">
      <form>
        <div class="columns is-centered">
          <div class="column is-4">
            <b-field expanded :label="t('common.accountType')" label-position="on-border">
              <b-select v-model="accountType" expanded>
                <option :value="3"> {{t('userRole.student')}}</option>
                <option :value="2">{{t('userRole.teacher')}}</option>
                <option :value="1">[!] {{t('userRole.teacherAdmin')}}</option>
                <option :value="0">[!] {{t('userRole.admin')}}</option>
              </b-select>
            </b-field>

            <b-field v-if="editUser">
              <ValidationProvider rules="required|email" v-slot="{ errors, valid }">
                <b-field
                  :label="t('common.email')"
                  label-position="on-border"
                  :type="{ 'is-danger': errors[0], 'is-success': valid }"
                  :message="t(errors[0])"
                >
                  <b-input
                    type="email"
                    v-model="localUser.newEmail"
                    maxlength="99"
                    :has-counter="false"
                    required
                  ></b-input>
                </b-field>
              </ValidationProvider>
            </b-field>
            <b-field v-else>
              <ValidationProvider rules="required|email" v-slot="{ errors, valid }">
                <b-field
                  :label="t('common.email')"
                  label-position="on-border"
                  :type="{ 'is-danger': errors[0], 'is-success': valid }"
                  :message="t(errors[0])"
                >
                  <b-input
                    type="email"
                    v-model="localUser.email"
                    maxlength="99"
                    :has-counter="false"
                    required
                  ></b-input>
                </b-field>
              </ValidationProvider>
            </b-field>

            <b-field>
              <ValidationProvider rules="required|min:2|max:99|surname" v-slot="{ errors, valid }">
                <b-field
                  :label="t('common.firstName')"
                  label-position="on-border"
                  :type="{ 'is-danger': errors[0], 'is-success': valid }"
                  :message="t(errors[0], { min: 2, max: 99 })"
                >
                  <b-input
                    v-model="localUser.firstName"
                    maxlength="99"
                    :has-counter="false"
                    required
                  ></b-input>
                </b-field>
              </ValidationProvider>
            </b-field>

            <b-field>
              <ValidationProvider rules="required|min:2|max:99|surname" v-slot="{ errors, valid }">
                <b-field
                  :label="t('common.lastName')"
                  label-position="on-border"
                  :type="{ 'is-danger': errors[0], 'is-success': valid }"
                  :message="t(errors[0], { min: 2, max: 99 })"
                >
                  <b-input
                    v-model="localUser.lastName"
                    maxlength="99"
                    :has-counter="false"
                    required
                  ></b-input>
                </b-field>
              </ValidationProvider>
            </b-field>

            <b-field v-if="!editUser">
              <ValidationProvider rules="required|userpass" v-slot="{ errors, valid }">
                <b-field
                  :label="t('common.password')"
                  label-position="on-border"
                  :type="{ 'is-danger': errors[0], 'is-success': valid }"
                  :message="t(errors[0])"
                >
                  <b-input
                    type="password"
                    v-model="localUser.password"
                    password-reveal
                    minlength="6"
                    :has-counter="false"
                  ></b-input>
                </b-field>
              </ValidationProvider>
            </b-field>

            <p class="has-text-centered">
              <b-field grouped group-multiline>
                <b-button
                  v-if="editUser"
                  class="mr-2"
                  icon-left="account-cancel"
                  :loading="isLoading"
                  @click="confirmDelete"
                  type="is-danger"
                >{{t('auth.removeUser')}}</b-button>
                <b-button
                  v-if="editUser"
                  class="mr-2"
                  icon-left="logout"
                  :loading="isLoading"
                  @click="confirmLogout"
                  type="is-warning"
                >{{t('auth.closeSession')}}</b-button>
                <b-button
                  @click="saveUser"
                  :loading="isLoading"
                  icon-left="account-check"
                  type="is-primary"
                  :disabled="invalid"
                >
                  <span v-if="editUser">{{t('common.saveChanges')}}</span>
                  <span v-else>{{t('auth.addUser')}}</span>
                </b-button>
              </b-field>
            </p>
          </div>
          <div class="column is-4" v-if="accountType === 3">
            <b-field :label="t('common.studentIndex')" label-position="on-border">
              <b-input v-model="localUser.studentIndex"></b-input>
            </b-field>
            <b-field :label="t('common.academy')" label-position="on-border">
              <b-select v-model="localUser.academy" expanded>
                <option
                  v-for="(university, index) in afmConfig.universities"
                  :key="index"
                  :value="university"
                >{{ university }}</option>
              </b-select>
            </b-field>

            <b-field :label="t('common.study')" label-position="on-border">
              <b-select v-model="localUser.studentStudy" expanded>
                <option
                  v-for="(subject, index) in afmConfig.subjects"
                  :key="index"
                  :value="subject"
                >{{ subject }}</option>
              </b-select>
            </b-field>
            <b-field :label="t('common.specialization')" label-position="on-border">
              <b-select v-model="localUser.studentSpec" expanded>
                <option
                  v-for="(spec, index) in afmConfig.specializations"
                  :key="index"
                  :value="spec"
                >{{ spec }}</option>
              </b-select>
            </b-field>
            <b-field :label="t('common.semester')" label-position="on-border">
              <b-input v-model="localUser.studentSemester"></b-input>
            </b-field>
            <!-- <b-field label="Kursy" label-position="on-border">
              <b-dropdown
                v-model="userCourses"
                expanded
                :scrollable="true"
                :max-height="200"
                multiple
                aria-role="list"
              >
                <template #trigger>
                  <b-field>
                    <b-field>
                      <b-input
                        :value="userCourses.length"
                        :readonly="true"
                        expanded
                      />
                      <p class="control">
                        <b-button icon-right="chevron-down"></b-button>
                      </p>
                    </b-field>
                  </b-field>
                </template>
                <b-dropdown-item disabled>
                  <div class="columns">
                    <div class="column is-one-quarter">
                      <strong>Uczelnia</strong>
                    </div>
                    <div class="column is-one-quarter">
                      <strong>Nazwa</strong>
                    </div>
                    <div class="column is-one-quarter">
                      <strong>Poziom</strong>
                    </div>
                    <div class="column is-one-quarter">
                      <strong>Data</strong>
                    </div>
                  </div>
                </b-dropdown-item>
                <b-dropdown-item
                  v-for="course in filteredCourses"
                  :key="course.id"
                  :value="course.id"
                  aria-role="listitem"
                >
                  <div class="columns">
                    <div class="column is-one-quarter">
                      {{ course.academy }}
                    </div>
                    <div class="column is-one-quarter">{{ course.name }}</div>
                    <div class="column is-one-quarter">
                      {{ mGetLevelCourseName(course.type) }}
                    </div>
                    <div class="column is-one-quarter">
                      {{ mDate(course.starts) }} - {{ mDate(course.ends) }}
                    </div>
                  </div>
                </b-dropdown-item>
              </b-dropdown>
            </b-field>-->
            <b-field :label="t('common.courses')" label-position="on-border">
              <b-taginput
                v-model="userCourses"
                :data="filteredCourses"
                autocomplete
                :allow-new="false"
                open-on-focus
                type="is-primary"
                field="name"
                :placeholder="t('common.search')"
                clos
                expanded
              >
                <template v-slot="props">
                  <p>
                    <strong>{{props.option.name}}</strong>
                  </p>
                  <p>{{t('common.academy')}}: {{props.option.academy}}</p>
                  <p>{{t('common.level')}}: {{ mGetLevelCourseName(props.option.type) }}</p>
                  <p>{{ mDate(props.option.starts) }} - {{ mDate(props.option.ends) }}</p>
                </template>
                <template #empty>
                  <span v-if="localUser.academy">{{t('course.noCoursesForAcademy')}}: {{localUser.academy}}</span>
                  <span v-else>{{t('common.chooseAcademy')}}</span>
                </template>
              </b-taginput>
            </b-field>
            <b-field :label="t('common.groups')" label-position="on-border">
              <b-taginput
                v-model="localUser.studentGroups"
                ellipsis
                icon="plus"
                :placeholder="t('common.add')"
                type="is-primary"
                :aria-close-label="t('common.remove')"
              ></b-taginput>
            </b-field>
          </div>
          <div class="column is-4" v-else-if="accountType === 2 || accountType === 1">
            <b-field :label="t('common.teacherPrefix')" label-position="on-border">
              <b-input v-model="localUser.teacherPrefix"></b-input>
            </b-field>
            <b-field :label="t('common.teacherId')" label-position="on-border">
              <b-input v-model="localUser.teacherId"></b-input>
            </b-field>
            <b-field :label="t('common.academy')" label-position="on-border">
              <b-select v-model="localUser.academy" expanded>
                <option
                  v-for="(university, index) in afmConfig.universities"
                  :key="index"
                  :value="university"
                >{{ university }}</option>
              </b-select>
            </b-field>
            <!-- <b-field label="Kursy" label-position="on-border">
              <b-dropdown
                v-model="userCourses"
                expanded
                :scrollable="true"
                :max-height="200"
                multiple
                aria-role="list"
              >
                <template #trigger>
                  <b-field>
                    <b-input
                      :value="userCourses.length"
                      :readonly="true"
                      expanded
                    />
                    <b-button icon-right="menu-down"></b-button>
                  </b-field>
                </template>
                <b-dropdown-item disabled>
                  <div class="columns">
                    <div class="column is-one-quarter">
                      <strong>Uczelnia</strong>
                    </div>
                    <div class="column is-one-quarter">
                      <strong>Nazwa</strong>
                    </div>
                    <div class="column is-one-quarter">
                      <strong>Poziom</strong>
                    </div>
                    <div class="column is-one-quarter">
                      <strong>Data</strong>
                    </div>
                  </div>
                </b-dropdown-item>
                <b-dropdown-item
                  v-for="course in filteredCourses"
                  :key="course.id"
                  :value="course.id"
                  aria-role="listitem"
                >
                  <div class="columns">
                    <div class="column is-one-quarter">
                      {{ course.academy }}
                    </div>
                    <div class="column is-one-quarter">{{ course.name }}</div>
                    <div class="column is-one-quarter">
                      {{ mGetLevelCourseName(course.type) }}
                    </div>
                    <div class="column is-one-quarter">
                      {{ mDate(course.starts) }} - {{ mDate(course.ends) }}
                    </div>
                  </div>
                </b-dropdown-item>
              </b-dropdown>
            </b-field>-->

            <b-field :label="t('common.courses')" label-position="on-border">
              <b-taginput
                v-model="userCourses"
                :data="filteredCourses"
                autocomplete
                :allow-new="false"
                open-on-focus
                type="is-primary"
                field="name"
                :placeholder="t('common.search')"
                clos
                expanded
                :loading="isLoadingCourse"
              >
                <template v-slot="props">
                  <p>
                    <strong>{{props.option.name}}</strong>
                  </p>
                  <p>{{t('common.academy')}}: {{props.option.academy}}</p>
                  <p>{{t('common.level')}}: {{ mGetLevelCourseName(props.option.type) }}</p>
                  <p>{{ mDate(props.option.starts) }} - {{ mDate(props.option.ends) }}</p>
                </template>
                <template #empty>
                  <span v-if="localUser.academy">{{t('course.noCoursesForAcademy')}}: {{localUser.academy}}</span>
                  <span v-else>{{t('common.chooseAcademy')}}</span>
                </template>
              </b-taginput>
            </b-field>
            <b-field :label="t('common.address')" label-position="on-border">
              <b-input v-model="localUser.teacherAddress" type="textarea"></b-input>
            </b-field>
            <b-field :label="t('common.groups')" label-position="on-border">
              <b-taginput
                v-model="localUser.teacherGroups"
                ellipsis
                icon="plus"
                :placeholder="t('common.add')"
                type="is-primary"
                :aria-close-label="t('common.remove')"
              ></b-taginput>
            </b-field>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { Action } from "../../store/config_actions";
import Commons from "@/mixins/commons";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import TypesHelpers from "@/mixins/types";
import UserFeedback from "@/components/feedbacks/UserFeedback";

export default {
  name: "AuthUsersCreator",
  mixins: [Commons, TypesHelpers],
  components: { ValidationProvider, ValidationObserver, UserFeedback },

  data: function () {
    return {
      isLoading: false,
      isLoadingCourse: false,
      accountType: 3,
      tempUser: {},
      localUser: {},
      courses: [],
      afmConfig: {},
      startUserCourses: [],
      userCourses: [],
      countUpdatedCourse: 0,
      maxUpdatedCourse: 0,
      newUser: {
        roles: ["Student"],
        email: null,
        firstName: null,
        lastName: null,
        password: null,
        studentYear: 0,
        studentIndex: null,
        academy: null,
        studentStudy: null,
        studentSpec: null,
        studentSemester: null,
        studentCourses: [],
        studentGroups: [],
        teacherId: null,
        teacherAddress: null,
        teacherGroups: [],
        teacherCourses: [],
        teacherPrefix: null
      },
    };
  },

  props: {
    editUser: { type: Object, required: false },
  },

  computed: {
    filteredCourses() {
      if (this.courses) {
        let filtered = this.courses;
        filtered = filtered.filter(
          (ex) => ex.academy === this.localUser.academy
        );
        return filtered;
      } else return [];
    },
  },

  watch: {
    accountType(val) {
      switch (val) {
        case 0:
          this.localUser.roles = ["Admin"];
          break;
        case 1:
          this.localUser.roles = ["Admin", "Teacher"];
          break;
        case 2:
          this.localUser.roles = ["Teacher"];
          break;
        default:
          this.localUser.roles = ["Student"];
          break;
      }
    },

    countUpdatedCourse() {
      if (this.countUpdatedCourse === this.maxUpdatedCourse) {
        this.isLoading = false;
      }
    },

    editUser() {
      this.setEditUser();
    }
  },

  mounted() {
    if (this.editUser) {
      this.tempUser = JSON.parse(JSON.stringify(this.editUser));
      this.convertUser();
      this.setAccountType();
      if (this.accountType === 2 || this.accountType === 1) {
        this.getMultiCourse(this.localUser.teacherCourses, false, null);
      } else if (this.accountType === 3) {
        this.getMultiCourse(this.localUser.studentCourses, false, null);
      }
    } else {
      this.localUser = JSON.parse(JSON.stringify(this.newUser));
    }
    this.getCourses();
    this.getAfmConfig();
  },

  methods: {
    setEditUser() {
      this.tempUser = JSON.parse(JSON.stringify(this.editUser));
      this.convertUser();
      this.setAccountType();
      if (this.accountType === 2 || this.accountType === 1) {
        this.getMultiCourse(this.localUser.teacherCourses, false, null);
      } else if (this.accountType === 3) {
        this.getMultiCourse(this.localUser.studentCourses, false, null);
      }
    },

    saveUser() {
      if (this.accountType === 2 || this.accountType === 1) {
        this.localUser.teacherCourses = this.userCourses.map((c) => c.id);
      } else if (this.accountType === 3) {
        this.localUser.studentCourses = this.userCourses.map((c) => c.id);
      }

      if (this.editUser) {
        this.updateUser();
      } else {
        this.addUser();
      }
    },
    /**
     * Register new user
     */
    addUser() {
      this.isLoading = true;

      this.$store
        .dispatch(Action.AUTH_ADD_USER, this.localUser)
        .then((payload) => {
          if (this.accountType === 2 || this.accountType === 1) {
            this.getMultiCourse(payload.teacherCourses, true, payload.id);
          } else if (this.accountType === 3) {
            this.getMultiCourse(payload.studentCourses, true, payload.id);
          }
          this.mSuccessSnack(this.t('auth.userAdded'));
          this.$emit("user-added");
          this.localUser = JSON.parse(JSON.stringify(this.newUser));
          this.setAccountType();
          this.userCourses = [];
          this.isLoading = false;
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },

    convertUser() {
      this.localUser = {
        roles: this.tempUser.roles,
        currentEmail: this.tempUser.email,
        newEmail: this.tempUser.email,
        firstName: this.tempUser.firstName,
        lastName: this.tempUser.lastName,
        password: this.tempUser.password,
        studentYear: this.tempUser.studentYear,
        studentIndex: this.tempUser.studentIndex,
        academy: this.tempUser.academy,
        studentStudy: this.tempUser.studentStudy,
        studentSpec: this.tempUser.studentSpec,
        studentSemester: this.tempUser.studentSemester,
        studentCourses: this.tempUser.studentCourses,
        studentGroups: this.tempUser.studentGroups,
        teacherId: this.tempUser.teacherId,
        teacherAddress: this.tempUser.teacherAddress,
        teacherGroups: this.tempUser.teacherGroups,
        teacherCourses: this.tempUser.teacherCourses,
        teacherPrefix: this.tempUser.teacherPrefix
      };
    },

    setAccountType() {
      if (["Admin", "Teacher"].every((r) => this.localUser.roles.includes(r))) {
        this.accountType = 1;
      } else if (["Admin"].every((r) => this.localUser.roles.includes(r))) {
        this.accountType = 0;
      } else if (["Teacher"].every((r) => this.localUser.roles.includes(r))) {
        this.accountType = 2;
      } else {
        this.accountType = 3;
      }
    },

    updateUser() {
      this.$store
        .dispatch(Action.AUTH_MODIFY_USER, this.localUser)
        .then(() => {
          this.differenceCourse(this.editUser.id);
          this.mSuccessSnack(this.t('auth.userUpdated'));
          this.$emit("user-update");
          this.isLoading = false;
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },

    /** confirm Delete user */
    confirmDelete() {
      this.$buefy.dialog.confirm({
        title: this.t('auth.removeUser'),
        message: this.t('auth.removeUserMessage'),
        onConfirm: () => this.confirmDeleteSecond(),
        type: "is-danger",
        hasIcon: true,
        cancelText: this.t('common.no'),
        confirmText: this.t('common.yes'),
      });
    },

    /** second confirm Delete user */
    confirmDeleteSecond() {
      this.$buefy.dialog.confirm({
        title: this.t('auth.removeUserConfirm'),
        message:this.t('auth.removeuserConfirmMessage'),
        onConfirm: () => this.deleteUser(),
        type: "is-danger",
        hasIcon: true,
        cancelText: this.t('common.no'),
        confirmText: this.t('common.yes'),
      });
    },

    deleteUser() {
      this.isLoading = true;
      this.$store
        .dispatch(Action.AUTH_DELETE_USER, this.editUser.id)
        .then(() => {
          this.mSuccessSnack(this.t('auth.userRemoved'));
          if (this.accountType === 2 || this.accountType === 1) {
            this.getMultiCourse(
              this.editUserUser.teacherCourses,
              true,
              this.editUser.id
            );
          } else if (this.accountType === 3) {
            this.getMultiCourse(
              this.editUser.studentCourses,
              true,
              this.editUser.id
            );
          }
          this.$emit("user-update");
          this.isLoading = false;
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },

    confirmLogout() {
      this.$buefy.dialog.confirm({
        title: this.t('auth.closeSessionConfirm'),
        message: this.t('auth.closeSessionConfirmMessage'),
        onConfirm: () => this.logoutUser(),
        type: "is-danger",
        hasIcon: true,
        cancelText: this.t('common.no'),
        confirmText: this.t('common.yes'),
      });
    },

    logoutUser() {
      this.isLoading = true;
      this.$store
        .dispatch(Action.AUTH_LOGOUT_USER, this.editUser.email)
        .then(() => {
          this.mSuccessSnack(this.t('auth.sessionClosed'));
          this.isLoading = false;
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },

    /**
     * Download all courses
     */
    getCourses() {
      this.isLoading = true;
      this.$store
        .dispatch(Action.COURSE_GET_ALL)
        .then((payload) => {
          this.courses = payload;
          this.isLoading = false;
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },

    differenceCourse(addUserId) {
      this.maxUpdatedCourse = 0;
      let userCourseIds = this.userCourses.map((c) => c.id)
      let courseToRemove = this.startUserCourses.filter(
        (x) => !userCourseIds.includes(x)
      );
      let courseToAdd = userCourseIds.filter(
        (x) => !this.startUserCourses.includes(x)
      );

      if (courseToRemove) {
        this.maxUpdatedCourse += courseToRemove.length;
      }

      if (courseToAdd) {
        this.maxUpdatedCourse = courseToAdd.length;
      }

      if (courseToAdd && courseToAdd.length > 0) {
        this.getMultiCourse(courseToAdd, true, addUserId);
      }

      if (courseToRemove && courseToRemove.length > 0) {
        this.removeUserToCourse(courseToRemove, addUserId);
      }
    },

    /**
     * Get AFM config
     */
    getAfmConfig() {
      this.isLoading = true;
      if (this.$store.state.afmconfig.config) {
        this.afmConfig = this.$store.state.afmconfig.config;
        this.isLoading = false;
      } else {
        this.$store
          .dispatch(Action.AFM_CONFIG_GET)
          .then((payload) => {
            this.afmConfig = payload;
            this.isLoading = false;
          })
          .catch((error) => {
            this.mDangerSnack(error.toString());
            this.isLoading = false;
          });
      }
    },

    /**
     * Get multiple courses
     */
    getMultiCourse(selectedCourse, isUpdatedCourse, userId) {
      this.isLoading = true;
      this.isLoadingCourse = true;
      let send = { included: selectedCourse };
      this.$store
        .dispatch(Action.COURSE_GET_MULTIPLE, send)
        .then((payload) => {
          if (isUpdatedCourse) {
            this.addUserToCourse(payload, userId);
          } else {
            this.userCourses = payload ?? [];
            this.startUserCourses = payload.map((c) => c.id);
            this.startUserCoursesFull = payload;
            this.isLoading = false;
            this.isLoadingCourse = false;
          }
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
          this.isLoadingCourse = false;
        });
    },

    addUserToCourse(coursesToUpdated, userId) {
      if (userId) {
        coursesToUpdated.forEach((course) => {
          if (this.accountType === 2 || this.accountType === 1) {
            if (!course.teachers.includes(userId)) {
              course.teachers.push(userId);
            }
          } else if (this.accountType === 3) {
            if (!course.teachers.includes(userId)) {
              course.students.push(userId);
            }
          }
          this.updateCourse(course);
        });
      }
    },

    removeUserToCourse(coursesToUpdated, userId) {
      if (userId) {
        coursesToUpdated.forEach((courseId) => {
          let course = this.startUserCoursesFull.find((c) => c.id === courseId);
          if (course) {
            if (this.accountType === 2 || this.accountType === 1) {
              let teacher = course.teachers.find((t) => t === userId);
              if (teacher) {
                course.teachers.splice(course.teachers.indexOf(teacher), 1);
              }
            } else if (this.accountType === 3) {
              let student = this.course.students.find((s) => s === userId);
              if (student) {
                course.students.splice(course.students.indexOf(student), 1);
              }
            }
            this.updateCourse(course);
          }
        });
      }
    },

    /**
     * Update course
     */
    updateCourse(updatedCourse) {
      this.isLoading = true;
      //this.currentCourse.classrooms.push(idClass);
      this.$store
        .dispatch(Action.COURSE_MODIFY, updatedCourse)
        .then(() => {
          this.countUpdatedCourse++;
          this.mSuccessSnack(this.t('course.successUpdate'));
          this.isLoading = false;
        })
        .catch((error) => {
          this.countUpdatedCourse++;
          this.mDangerSnack(error.toString());
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
div.end {
  float: right;
}
</style>
