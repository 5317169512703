<template>
  <div class="home">
    <AuthUsers />
  </div>
</template>

<script>
// @ is an alias to /src
import AuthUsers from '@/components/auth/AuthUsers.vue'

export default {
  name: 'UsersView',
  components: {
    AuthUsers
  }
}
</script>
