<template>
  <div class="pb-3">
    <UserFeedback :target=" t('componentNames.usersList')" class="end mr-4 mt-1"></UserFeedback>
    <p class="mb-1">
      <b-button
        :loading="isLoading"
        @click="getUsers"
        icon-left="refresh"
        size="is-small"
      >{{t('common.refreshList')}}</b-button>
    </p>
    <b-table
      narrowed
      paginated
      per-page="12"
      :data="users"
      striped
      hoverable
      detailed
      detail-key="email"
      :loading="isLoading"
    >
      <b-table-column
        field="email"
        searchable
        sortable
        :label="t('common.email')"
        v-slot="props"
      >{{ props.row.email }}</b-table-column>
      <b-table-column
        field="roles"
        searchable
        sortable
        :label="t('common.roles')"
        v-slot="props"
      >{{ mGetRoleTypesName(props.row.roles) }}</b-table-column>
      <b-table-column
        field="firstName"
        searchable
        sortable
        :label="t('common.firstName')"
        v-slot="props"
      >{{ props.row.firstName }}</b-table-column>
      <b-table-column
        field="lastName"
        searchable
        sortable
        :label="t('common.lastName')"
        v-slot="props"
      >{{ props.row.lastName }}</b-table-column>
      <template slot="detail" slot-scope="props">
        <AuthUsersCreator :editUser="props.row" @user-update="getUsers"></AuthUsersCreator>
        <!-- <pre>{{props.row}}</pre> -->
      </template>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon icon="package-variant" size="is-large"></b-icon>
            </p>
            <p>{{t('common.noData')}}</p>
          </div>
        </section>
      </template>
    </b-table>
  </div>
</template>

<script>
import { Action } from "../../store/config_actions";
import Commons from "@/mixins/commons";
import RoleHelpers from "@/mixins/roles"
import AuthUsersCreator from "./AuthUsersCreator";
import UserFeedback from '@/components/feedbacks/UserFeedback'

export default {
  name: "AuthUsers",
  mixins: [Commons, RoleHelpers],
  components: {
    AuthUsersCreator,
    UserFeedback
  },

  props: {
    refreshTrigger: { type: Number, required: true }
  },

  watch: {
    refreshTrigger() {
      this.getUsers()
    }
  },

  data: function () {
    return {
      users: [],
      isLoading: false,
    };
  },

  mounted() {
    this.getUsers();
  },

  methods: {

    /**
     * Download system users
     */
    getUsers() {
      this.isLoading = true;
      this.$store
        .dispatch(Action.AUTH_GET_USERS)
        .then((payload) => {
          this.users = payload;
          this.isLoading = false;
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
div.end {
  float: right;
}
</style>
