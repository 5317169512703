<template>
  <div class="card ml-5 mr-5 mt-4">
    <b-tabs :animated="false">
      <b-tab-item :label="t('common.users')" icon="account-multiple">
        <AuthUsersList :refreshTrigger="refreshTrigger"></AuthUsersList>
      </b-tab-item>
      <b-tab-item :label="t('auth.newUser')" icon="account-plus">
        <AuthUsersCreator @user-added="refreshTrigger++"></AuthUsersCreator>
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import Commons from "@/mixins/commons";
//import AuthUsersAdd from "./AuthUsersAdd";
import AuthUsersList from "./AuthUsersList";
import AuthUsersCreator from "./AuthUsersCreator";

export default {
  name: "AuthUsers",
  mixins: [Commons],
  components: { AuthUsersList, AuthUsersCreator },

  data: function () {
    return {
      refreshTrigger: 0,
    };
  },

  mounted() { },

  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card {
  overflow: visible !important;
}
</style>
